import { createSlice } from '@reduxjs/toolkit'
import { initialState } from '../initialState'
import { StoreType } from '../store-types'

// TODO state can be mutated
export const activeRowSlice = createSlice({
  name: 'ActiveRow',
  initialState: initialState.activeRow,
  reducers: {
    activateRow: (state, action) => {
      return {
        ...state,
        activeRowGroupID: action.payload,
      }
    },
    deactivateRow: (state) => {
      return {
        ...state,
        activeRowGroupID: null,
      }
    },
    highlightRow: (state, action) => {
      return {
        ...state,
        highlightedRowGroupID: action.payload,
      }
    },
    lowlightRow: (state) => {
      return {
        ...state,
        highlightedRowGroupID: null,
      }
    },
    setMaxError: (state, action) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.errorGroupID]: {
            groupID: action.payload.errorGroupID,
            licenseType: action.payload.errorLicenseType,
            message: action.payload.errorMessage,
            forceFocus: action.payload.forceFocus,
          },
        },
      }
    },
    unsetMaxError: (state, action) => {
      const customizedGroupID = `${action.payload.groupID}-${action.payload.licenseType}`
      return {
        ...state,
        errors: Object.fromEntries(
          Object.entries(state.errors || {}).filter(
            ([k]) => k !== customizedGroupID
          )
        ),
      }
    },
  },
})

export const {
  activateRow,
  deactivateRow,
  highlightRow,
  lowlightRow,
  setMaxError,
  unsetMaxError,
} = activeRowSlice.actions

export const selectActiveRow = (state: StoreType) => state.activeRow
export const selectActiveRowErrors = (state: StoreType) =>
  state.activeRow.errors

export default activeRowSlice.reducer
