import { fetchClient } from './api'

const data = {
  method: 'POST',
  body: {
    fields: JSON.stringify([
      'customer:licenseTypeVersion',
      'customer:isIMSEnabled',
    ]),
    filters: JSON.stringify({ ID: '$$USER' }),
  },
}

export const CustomerService = {
  loadCustomer() {
    // @ts-ignore
    return fetchClient(`/attask/api-internal/user/search`, data)
  },
}
