import React, { useCallback } from 'react'
import { primary } from '@phoenix/all'
// @ts-ignore
import { css, cx } from 'react-emotion'
import {
  LICENSE_TYPE_NAMES,
  LICENSE_TYPES,
} from '../../../../../constants/constants'
import {
  highlightRow,
  lowlightRow,
} from '../../../../../store/activeRow/activeRowSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { Group } from '../../../../../types/Group'
import { Cell, Row } from '../../TableHeader/TableHeader'
import {
  fullWidthCss,
  TableBodyDataRowMaxColumn,
} from './TableBodyDataRowMaxColumn/TableBodyDataRowMaxColumn'

interface TableBodyDataRowProps {
  data: Group
  isRowHighlighted: boolean
  isRowActive: boolean
}

function TableBodyDataRow({
  data,
  isRowHighlighted,
  isRowActive,
}: TableBodyDataRowProps) {
  const dispatch = useAppDispatch()

  const stylizedClass =
    isRowHighlighted || isRowActive ? cx(isHoveredCss, columnCss) : columnCss
  const planLicenses = data?.licenseTypeLimit?.usedLicenses?.plan ?? ''
  const workLicenses = data?.licenseTypeLimit?.usedLicenses?.work ?? ''
  const reviewLicenses = data?.licenseTypeLimit?.usedLicenses?.review ?? ''
  const requestLicenses = data?.licenseTypeLimit?.usedLicenses?.request ?? ''
  const showRow = typeof data.licenseTypeLimit.usedLicenses !== 'undefined'

  const handleMouseEnter = useCallback(() => {
    dispatch(highlightRow(data.ID))
  }, [data.ID, dispatch])

  const handleMouseLeave = useCallback(() => {
    dispatch(lowlightRow())
  }, [dispatch])

  return showRow ? (
    <Row
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="table-content-row"
    >
      <Cell className={stylizedClass}>
        <div title={data.name} className={fullWidthCss}>
          {data.name}
        </div>
      </Cell>
      <Cell className={stylizedClass}>
        <div className={fullWidthCss} data-testid="used-plan-count">
          {planLicenses}
        </div>
        <TableBodyDataRowMaxColumn
          groupID={data.ID}
          maxValue={data.licenseTypeLimit.planLimit}
          licenseType={LICENSE_TYPES.plan}
          licenseTypeName={LICENSE_TYPE_NAMES.plan}
          usedLicenses={planLicenses}
          isRowHighlighted={isRowHighlighted}
          isRowActive={isRowActive}
        />
      </Cell>
      <Cell className={stylizedClass}>
        <div className={fullWidthCss} data-testid="used-work-count">
          {workLicenses}
        </div>
        <TableBodyDataRowMaxColumn
          groupID={data.ID}
          maxValue={data.licenseTypeLimit.workLimit}
          licenseType={LICENSE_TYPES.work}
          licenseTypeName={LICENSE_TYPE_NAMES.work}
          usedLicenses={workLicenses}
          isRowHighlighted={isRowHighlighted}
          isRowActive={isRowActive}
        />
      </Cell>
      <Cell className={stylizedClass}>
        <div className={fullWidthCss} data-testid="used-review-count">
          {reviewLicenses}
        </div>
      </Cell>
      <Cell className={stylizedClass}>
        <div className={fullWidthCss} data-testid="used-request-count">
          {requestLicenses}
        </div>
      </Cell>
    </Row>
  ) : null
}

export default TableBodyDataRow

export const columnCss = css`
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: -1px;
  border-top: 1px solid ${primary.gray(200)};
  border-bottom: 1px solid ${primary.gray(200)};
  padding: 0 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 17px;
`

const isHoveredCss = css`
  background-color: ${primary.gray(50)};
`
