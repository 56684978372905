import React, { useCallback, useState } from 'react'
// @ts-ignore
import { ObjectTypeahead } from '@wf-mfe/shared-components'
import { useLocalization } from '@workfront/localize-react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../store/hooks'
import {
  addGroup,
  selectManageGroupsDialogData,
  updateSaveButtonState,
} from '../../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { Group } from '../../../../types/Group'

export function GroupTypeahead() {
  const dispatch = useAppDispatch()
  const { dialogGroupItems } = useSelector(selectManageGroupsDialogData)
  const [isFocused, setIsFocused] = useState(false)

  const [typeaheadLabelText] = useLocalization(
    'licensemanagement.homegroups',
    'Home Groups'
  )

  const handleValuesChange = useCallback(
    (data: Group) => {
      if (isFocused && data) {
        const { ID, name, objCode, licenseTypeLimit = {} } = data
        dispatch(addGroup({ ID, name, objCode, licenseTypeLimit }))
        dispatch(updateSaveButtonState(true))
      }
    },
    [dispatch, isFocused]
  )

  const handleOnInputFocus = useCallback(() => setIsFocused(true), [])
  const handleOnInputBlur = useCallback(() => setIsFocused(false), [])

  return (
    <div data-testid="group-search-typeahead-container">
      <ObjectTypeahead
        filters={{
          ID: dialogGroupItems.map((item) => item.ID),
          ID_Mod: 'notin',
          parentID_Mod: 'isnull',
        }}
        label={typeaheadLabelText}
        objCode="GROUP"
        onChange={handleValuesChange}
        onInputFocus={handleOnInputFocus}
        onInputBlur={handleOnInputBlur}
      />
    </div>
  )
}
