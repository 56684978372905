import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { GroupService } from '../../api/groupService'
import {
  LicenseTypeLimitPartial,
  SetLicenseTypeLimit,
} from '../../types/License'
import { parseMaxValue } from '../../utils/utils'
import { setMaxError, unsetMaxError } from '../activeRow/activeRowSlice'
import { initialState } from '../initialState'
import { StoreType } from '../store-types'

export const groupsSlice = createSlice({
  name: 'Groups',
  initialState: initialState.groups,
  reducers: {
    setGroups: (state, action) => {
      return action.payload
    },
    setMaxUpdated: (state, action) => {
      const value = parseMaxValue(action.payload.value)

      const licenseTypeLimit: Partial<LicenseTypeLimitPartial> = {}
      const licenseTypeLimitName =
        `${action.payload.licenseTypeName}Limit` as keyof LicenseTypeLimitPartial
      licenseTypeLimit[licenseTypeLimitName] = value

      return state.map((group) => {
        if (group.ID === action.payload.groupID) {
          return {
            ...group,
            licenseTypeLimit: {
              ...group.licenseTypeLimit,
              ...licenseTypeLimit,
            },
          }
        }
        return group
      })
    },
  },
})

export const { setGroups, setMaxUpdated } = groupsSlice.actions

export const selectGroups = (state: StoreType) => state.groups

export const loadGroups = () => async (dispatch: Dispatch) => {
  const data = await GroupService.loadGroups()
  dispatch(setGroups(data.data))
}

export const setLicenseTypeLimit =
  (data: SetLicenseTypeLimit) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        setMaxUpdated({
          groupID: data.ID,
          licenseType: data.type,
          value: data.value,
          licenseTypeName: data.licenseTypeName,
        })
      )
      const {
        data: { result },
      } = await GroupService.setMax(data)
      if (result === null) {
        dispatch(
          unsetMaxError({
            groupID: data.ID,
            licenseType: data.type,
          })
        )
      }
    } catch (e: any) {
      setMaxError({
        errorGroupID: `${data.ID}-${data.type}`,
        errorLicenseType: data.type,
        errorMessage: e.message,
        forceFocus: data.forceFocus,
      })
    }
  }

export default groupsSlice.reducer
