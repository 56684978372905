import React, { useRef } from 'react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import { HEIGHT_OF_ELEMENTS_ABOVE_THE_TABLE_CONTENT } from '../../../../constants/constants'
import { selectActiveRow } from '../../../../store/activeRow/activeRowSlice'
import { selectGroups } from '../../../../store/groups/groupsSlice'
import { selectManageAccess } from '../../../../store/summary/summarySlice'
import { Group } from '../../../../types/Group'
import TableBodyDataRow from './TableBodyDataRow/TableBodyDataRow'
import TableBodyFirstRow from './TableBodyFirstRow/TableBodyFirstRow'

export function TableContent() {
  const { activeRowGroupID, highlightedRowGroupID } =
    useSelector(selectActiveRow)
  const { isPackageSupportsManage, isGroupAdmin } =
    useSelector(selectManageAccess)
  const groups = useSelector(selectGroups)
  const containerRef = useRef<HTMLDivElement>(null)
  const top =
    containerRef?.current?.getBoundingClientRect().y ||
    HEIGHT_OF_ELEMENTS_ABOVE_THE_TABLE_CONTENT

  const rowItems = groups.map((groupItem: Group) => {
    return (
      <TableBodyDataRow
        key={groupItem.ID}
        data={groupItem}
        isRowHighlighted={highlightedRowGroupID === groupItem.ID}
        isRowActive={activeRowGroupID === groupItem.ID}
      />
    )
  })

  return isPackageSupportsManage && groups.length > 0 ? (
    <div ref={containerRef} data-testid="licenses-table-content">
      <TableContentStyled top={top}>
        {!isGroupAdmin && groups.length > 0 && <TableBodyFirstRow />}
        <div>{rowItems}</div>
      </TableContentStyled>
    </div>
  ) : null
}

type TableContentStyledProps = {
  top: number
}

export const TableContentStyled = styled('div')<TableContentStyledProps>`
  cursor: pointer;
  ${(props: TableContentStyledProps) =>
    `height: calc(100vh - ${props.top}px - 10px);`}
  overflow: scroll;
`
