import React from 'react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import {
  selectLicenses,
  selectManageAccess,
} from '../../../../store/summary/summarySlice'
import { TableColumnHeader } from './TableColumnHeader/TableColumnHeader'
import { TableFirstColumnHeader } from './TableFirstColumnHeader/TableFirstColumnHeader'

export function TableHeader() {
  const {
    standardLicenses,
    standardLicensesUsed,
    lightLicenses,
    lightLicensesUsed,
    freeLicenses,
    freeLicensesUsed,
    freeLicensesUnlimited,
  } = useSelector(selectLicenses)

  const { isGroupAdmin, isPackageSupportsManage } =
    useSelector(selectManageAccess)

  const licensesPurchasedMessageKey = isGroupAdmin
    ? 'licensemanagement.licenses'
    : 'licensemanagement.licensespurchased'

  const licenseTypeDescriptionMessageFallback = isGroupAdmin
    ? 'Licenses'
    : 'Licenses purchased'

  return (
    <Layout data-testid="licenses-table-header">
      <Row>
        <Cell>
          <TableFirstColumnHeader />
        </Cell>
        <Cell>
          <TableColumnHeader
            hasMaxData
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            licenseCount={isGroupAdmin ? '' : standardLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Standard"
            licenseTypeMessageKey="licensemanagement.standard"
            usedCount={standardLicensesUsed}
          />
        </Cell>
        <Cell>
          <TableColumnHeader
            hasMaxData
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            licenseCount={isGroupAdmin ? '' : lightLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Light"
            licenseTypeMessageKey="licensemanagement.light"
            usedCount={lightLicensesUsed}
          />
        </Cell>
        <Cell>
          <TableColumnHeader
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            isUnlimited={freeLicensesUnlimited}
            licenseCount={isGroupAdmin ? '' : freeLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Contributor"
            licenseTypeMessageKey="licensemanagement.contributor"
            licenseTypeMessageFallbackUnlimited="Unlimited Contributor"
            licenseTypeMessageKeyUnlimited="licensemanagement.unlimitedcontributor"
            usedCount={freeLicensesUsed}
          />
        </Cell>
      </Row>
    </Layout>
  )
}

export const Layout = styled('div')``

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const Cell = styled('div')`
  width: 50%;
`
