import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { LicenseService } from '../../api/licenseService'
import { LICENSE_TYPE_NAMES } from '../../constants/constants'
import { Group } from '../../types/Group'
import { UsedLicenses } from '../../types/License'
import { selectIsNewLicenseTypeVersion } from '../customer/customerSlice'
import { selectGroups } from '../groups/groupsSlice'
import { initialState } from '../initialState'
import { StoreType } from '../store-types'

export const summarySlice = createSlice({
  name: 'Summary',
  initialState: initialState.summary,
  reducers: {
    setInfo: (state, action) => {
      const { isGroupAdmin, isPackageSupportsManage, helpLink, ...licenses } =
        action.payload
      return {
        ...state,
        helpLink,
        manageAccess: {
          isGroupAdmin,
          isPackageSupportsManage,
        },
        licenses,
      }
    },
    setLoadingError: (state, action) => {
      return {
        ...state,
        loadingState: {
          isLoading: state.loadingState.isLoading,
          loadingError: action.payload,
        },
      }
    },
    startLoading: (state) => {
      return {
        ...state,
        loadingState: {
          isLoading: true,
          loadingError: null,
        },
      }
    },
    finishLoading: (state) => {
      return {
        ...state,
        loadingState: {
          isLoading: false,
          loadingError: state.loadingState.loadingError,
        },
      }
    },
  },
})

export const { setLoadingError, startLoading, finishLoading, setInfo } =
  summarySlice.actions

export const selectSummary = (state: StoreType) => state.summary
export const selectLicenses = (state: StoreType) => state.summary.licenses
export const selectHelpLink = (state: StoreType) => state.summary.helpLink
export const selectManageAccess = (state: StoreType) =>
  state.summary.manageAccess
export const selectLoadingState = (state: StoreType) =>
  state.summary.loadingState
export const selectPlanLicensesUsed = (state: StoreType) =>
  state.summary.licenses.planLicensesUsed
export const selectWorkLicensesUsed = (state: StoreType) =>
  state.summary.licenses.workLicensesUsed
export const selectReviewLicensesUsed = (state: StoreType) =>
  state.summary.licenses.reviewLicensesUsed
export const selectRequestLicensesUsed = (state: StoreType) =>
  state.summary.licenses.requestLicensesUsed
export const selectStandardLicensesUsed = (state: StoreType) =>
  state.summary.licenses.standardLicensesUsed
export const selectLightLicensesUsed = (state: StoreType) =>
  state.summary.licenses.lightLicensesUsed
export const selectFreeLicensesUsed = (state: StoreType) =>
  state.summary.licenses.freeLicensesUsed

const reduceUsedLicenses =
  (licenseName: keyof UsedLicenses) => (acc: number, group: Group) =>
    acc + (group.licenseTypeLimit.usedLicenses?.[licenseName] ?? 0)

// TODO use functions, improve names, use comments to help the reader
export const selectPlanLicensesOther = createSelector(
  selectGroups,
  selectPlanLicensesUsed,
  (groups: Array<Group>, planLicensesUsed: number) =>
    planLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.plan), 0)
)

export const selectWorkLicensesOther = createSelector(
  selectGroups,
  selectWorkLicensesUsed,
  (groups: Array<Group>, workLicensesUsed: number) =>
    workLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.work), 0)
)

export const selectReviewLicensesOther = createSelector(
  selectGroups,
  selectReviewLicensesUsed,
  (groups: Array<Group>, reviewLicensesUsed: number) =>
    reviewLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.review), 0)
)

export const selectRequestLicensesOther = createSelector(
  selectGroups,
  selectRequestLicensesUsed,
  (groups: Array<Group>, requestLicensesUsed: number) =>
    requestLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.request), 0)
)

export const selectStandardLicensesOther = createSelector(
  selectGroups,
  selectStandardLicensesUsed,
  (groups: Array<Group>, standardLicensesUsed: number) =>
    standardLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.standard), 0)
)

export const selectLightLicensesOther = createSelector(
  selectGroups,
  selectLightLicensesUsed,
  (groups: Array<Group>, lightLicensesUsed: number) =>
    lightLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.light), 0)
)

export const selectFreeLicensesOther = createSelector(
  selectGroups,
  selectFreeLicensesUsed,
  (groups: Array<Group>, freeLicensesUsed: number) =>
    freeLicensesUsed -
    groups.reduce(reduceUsedLicenses(LICENSE_TYPE_NAMES.free), 0)
)

export const loadInfo =
  () => async (dispatch: Dispatch, getState: () => StoreType) => {
    dispatch(startLoading())
    try {
      let data
      if (selectIsNewLicenseTypeVersion(getState())) {
        data = await LicenseService.loadInfoV2()
      } else {
        data = await LicenseService.loadInfo()
      }
      dispatch(setInfo(data))
    } catch (e) {
      dispatch(setLoadingError(e))
    } finally {
      dispatch(finishLoading())
    }
  }

export default summarySlice.reducer
