import React, { useCallback } from 'react'
import { primary } from '@phoenix/all'
import CloseSmallIcon from 'phoenix-icons/dist/CloseSmallIcon.js'
import GroupLargeIcon from 'phoenix-icons/dist/GroupLargeIcon.js'
// @ts-ignore
import styled, { css } from 'react-emotion'
import { useAppDispatch } from '../../../../../../store/hooks'
import {
  filterGroups,
  removeGroup,
  updateSaveButtonState,
} from '../../../../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { Group } from '../../../../../../types/Group'

interface GroupItem {
  group: Group
}

export function GroupItem({ group }: GroupItem) {
  const dispatch = useAppDispatch()

  const deleteGroup = useCallback(() => {
    dispatch(removeGroup(group))
    dispatch(updateSaveButtonState(true))

    // Update filtered results
    dispatch(filterGroups([group.ID]))
  }, [dispatch, group])

  return (
    <ListItemStyled data-testid="group-list-item">
      <GroupLargeIcon />
      <GroupNameStyled>{group.name}</GroupNameStyled>
      <CloseSmallIcon
        className={cursorPointerCss}
        onClick={deleteGroup}
        data-testid="delete-group-icon"
      />
    </ListItemStyled>
  )
}

export const ListItemStyled = styled('li')`
  position: relative;
  padding: 6px 15px 6px 0;
  border-top: 1px solid ${primary.gray(50)};
  display: flex;
  align-items: center;
  gap: 10px;
`

export const GroupNameStyled = styled('div')`
  width: 100%;
`

export const cursorPointerCss = css`
  cursor: pointer;
`
