import React from 'react'
import { SetupHeader } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import SystemExtraLargeHeroIcon from 'phoenix-icons/dist/SystemExtraLargeHeroIcon.js'
import { InfoSection } from './InfoSection/InfoSection'

export function Header() {
  return (
    <SetupHeader
      icon={<SystemExtraLargeHeroIcon />}
      title={
        <Localization messageKey="license.management" fallback="Licenses" />
      }
      section={
        <Localization messageKey="setup.navigation.system" fallback="System" />
      }
      contextSensitiveHelpPageID="csh-license-management"
      additionalBanner={<InfoSection />}
    />
  )
}
