import React from 'react'
import { A, Tooltip } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
import moment from 'moment'
// @ts-ignore
import styled, { css } from 'react-emotion'
import { useSelector } from 'react-redux'
import { LICENSE_APPROVAL_LIMIT_HELP_LINK } from '../../../constants/constants'
import { selectCustomer } from '../../../store/customer/customerSlice'
import {
  selectLicenses,
  selectManageAccess,
} from '../../../store/summary/summarySlice'
import { standardDateFormatter } from '../../../utils/L18n'
import { SecondRowContentStyled } from '../../MainContent/TableSection/TableHeader/TableColumnHeader/TableColumnHeader'
import { Row } from '../../MainContent/TableSection/TableHeader/TableHeader'

export function InfoSection() {
  const {
    edition,
    showAlignLicenseCount,
    showAlignLicensesAsUnlimited,
    alignUsersUsage,
    alignUsersLimit,
    showProofLicenseCount,
    usersUsage,
    usersLimit,
    expirationDate,
    licenseApprovalLimit,
  } = useSelector(selectLicenses)

  const { isGroupAdmin } = useSelector(selectManageAccess)
  const { isIMSEnabled } = useSelector(selectCustomer)

  const currentDate = moment()
  const expirationDateMoment = moment(Number(expirationDate)).unix()
  const daysBeforeExpiration =
    moment.unix(expirationDateMoment).diff(currentDate, 'days') + 1
  const formattedDate = standardDateFormatter.format(Number(expirationDate))

  const tooltipContent = (
    <>
      <Localization
        messageKey="licensemanagement.the.number.of"
        fallback="The number of"
      />
      {` `}
      <A
        className={licenseApprovalLimitHelpLinkCss}
        href={LICENSE_APPROVAL_LIMIT_HELP_LINK}
        target="_blank"
      >
        <Localization
          messageKey="licensemanagement.proof.and.document.decisions.available"
          fallback="proof and document decisions available"
        />
      </A>
      {'\n'}
      <Localization
        messageKey="licensemanagement.for.each.non.paid.license"
        fallback="for each non-paid Workfront license on your plan."
      />
    </>
  )

  return (
    <InfoSectionStyled>
      <Row className={rowCss}>
        {showProofLicenseCount && !isGroupAdmin && (
          <div data-testid="proof-licenses">
            <InfoBlockTitleStyled>
              <Localization
                messageKey="licensemanagement.prooflicenses"
                fallback="Proof licenses"
              />
            </InfoBlockTitleStyled>
            <InfoBlockStyled data-testid="proof-licenses-count">
              {`${usersUsage} `}
              <Localization messageKey="licensemanagement.of" fallback="of" />
              {` ${usersLimit}`}
            </InfoBlockStyled>
          </div>
        )}
        {showAlignLicenseCount && (
          <div data-testid="goals-licenses">
            <InfoBlockTitleStyled>
              <Localization
                messageKey="licensemanagement.alignlicenses"
                fallback="Workfront Goals licenses"
              />
            </InfoBlockTitleStyled>
            {showAlignLicensesAsUnlimited ? (
              <InfoBlockStyled data-testid="goals-licenses-unlimited">
                <Localization
                  messageKey="licensemanagement.unlimited"
                  fallback="Unlimited"
                />
              </InfoBlockStyled>
            ) : (
              <InfoBlockStyled>
                {`${alignUsersUsage} `}
                <Localization messageKey="licensemanagement.of" fallback="of" />
                {` ${alignUsersLimit}`}
              </InfoBlockStyled>
            )}
          </div>
        )}
        <div data-testid="workfront-edition">
          <InfoBlockTitleStyled>
            <Localization
              messageKey="licensemanagement.workfront.edition"
              fallback="Workfront Plan"
            />
          </InfoBlockTitleStyled>
          <InfoBlockStyled data-testid="workfront-edition-value">
            {edition}
          </InfoBlockStyled>
        </div>
        {!Number.isNaN(parseFloat(licenseApprovalLimit)) && (
          <div data-testid="monthly-decision-allotment">
            <Tooltip content={tooltipContent} position="bottom-end">
              <InfoBlockTitleStyled>
                <Localization
                  messageKey="licensemanagement.monthly.decision.allotment"
                  fallback="Monthly Decision Allotment"
                />
              </InfoBlockTitleStyled>
              <InfoBlockStyled data-testid="monthly-decision-allotment-value">
                {licenseApprovalLimit}
              </InfoBlockStyled>
            </Tooltip>
          </div>
        )}
        {expirationDate !== 'null' && !isIMSEnabled && (
          <div data-testid="contract-expiration-date">
            <InfoBlockTitleStyled>
              <Localization
                messageKey="licensemanagement.contract.expiration.date"
                fallback="Contract Renewal Date"
              />
            </InfoBlockTitleStyled>
            <InfoBlockStyled data-testid="contract-expiration-date-value">
              <Localization
                messageKey="licensemanagement.contract.expiration.date.and.days.left"
                fallback="{date} ( {count, plural, =0 {No days left} one {# day left} other {# days left}} )"
                args={{ date: formattedDate, count: daysBeforeExpiration }}
              />
            </InfoBlockStyled>
          </div>
        )}
      </Row>
    </InfoSectionStyled>
  )
}

const InfoSectionStyled = styled('div')`
  margin-left: auto;
  margin-bottom: -10px;
`

const InfoBlockTitleStyled = styled(SecondRowContentStyled)`
  margin-bottom: 6px;
`

const InfoBlockStyled = styled('div')`
  font-size: 14px;
`

const rowCss = css`
  gap: 32px;
`

const licenseApprovalLimitHelpLinkCss = css`
  &,
  &:hover,
  &:visited {
    color: white;
  }
`
