import React, { useCallback } from 'react'
import { A, Button, primary } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
// @ts-ignore
import styled, { css } from 'react-emotion'
import { useSelector } from 'react-redux'
import { selectGroups } from '../../../store/groups/groupsSlice'
import { useAppDispatch } from '../../../store/hooks'
import { setDialogState } from '../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import {
  selectHelpLink,
  selectLoadingState,
  selectManageAccess,
} from '../../../store/summary/summarySlice'

export function ManageGroupListInfoSection() {
  const dispatch = useAppDispatch()
  const { isPackageSupportsManage, isGroupAdmin } =
    useSelector(selectManageAccess)
  const { isLoading } = useSelector(selectLoadingState)
  const helpLink = useSelector(selectHelpLink)
  const groups = useSelector(selectGroups)

  const [noDataMessage] = useLocalization(
    'licensemanagement.no.records.message.part.2',
    'Learn more about license management.'
  )

  const handleClick = useCallback(() => {
    dispatch(setDialogState(true))
  }, [dispatch])

  // TODO useMemo
  const showSection =
    isPackageSupportsManage &&
    !isLoading &&
    !isGroupAdmin &&
    groups.length === 0

  return showSection ? (
    <SectionContainerStyled>
      <DescriptionStyled>
        <Localization
          messageKey="licensemanagement.no.records.message.part.1"
          fallback="To set a maximum, you must add a Home Group."
        />
        <A className={noDataHelpLinkCss} href={helpLink} target="_blank">
          {` ${noDataMessage}`}
        </A>
        <ButtonContainer>
          <Button onClick={handleClick}>
            <Localization
              messageKey="licensemanagement.managegrouplist"
              fallback="Manage Group List"
            />
          </Button>
        </ButtonContainer>
      </DescriptionStyled>
    </SectionContainerStyled>
  ) : null
}

export const SectionContainerStyled = styled('div')`
  height: 200px;
  box-shadow: inset 0 -1px 0 0 ${primary.gray(200)};
`
// TODO tailwind css
const DescriptionStyled = styled('div')`
  text-align: center;
  padding: 64px 0;
  color: ${primary.gray(400)};
  font-size: 16px;
`

export const noDataHelpLinkCss = css`
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`

export const ButtonContainer = styled('div')`
  margin-top: 16px;
`
