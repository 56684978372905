import { LicenseTypeNames } from '../types/License'

export const NUMERIC_REGEX = /^[0-9]+$/
export const NULL_VALUE_FOR_MAX = -2147483648
export const LICENSE_TYPES = {
  plan: 'F',
  work: 'T',
  review: 'C',
  request: 'R',
  standard: 'F',
  light: 'C',
  free: 'R',
}
export const GROUPS_LIMIT = 50
// This is some hardcoded/calculated value to just in case we couldn't get the top value of the table element
export const HEIGHT_OF_ELEMENTS_ABOVE_THE_TABLE_CONTENT = 340

export const LICENSE_TYPE_NAMES: LicenseTypeNames = {
  plan: 'plan',
  work: 'work',
  review: 'review',
  request: 'request',
  standard: 'standard',
  light: 'light',
  free: 'free',
}

export const LICENSE_APPROVAL_LIMIT_HELP_LINK =
  'https://experienceleague.adobe.com/docs/workfront/using/review-and-approve-work/proof-doc-decision-limits.html'
