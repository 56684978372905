import React from 'react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import { selectIsNewLicenseTypeVersion } from '../../store/customer/customerSlice'
import { ManageGroupListInfoSection } from './ManageGroupListInfoSection/ManageGroupListInfoSection'
import { TableSection } from './TableSection/TableSection'
import { TableSectionV2 } from './TableSectionV2/TableSectionV2'

/* TODO: Depending on license type version we render 2 different tables.
    Actually there are only 3 components that are different: TableBodyFirstRow, TableBodyDataRow and TableHeader */

export function MainContent() {
  const isNewLicenseTypeVersion = useSelector(selectIsNewLicenseTypeVersion)
  return (
    <MainContentStyled>
      {isNewLicenseTypeVersion ? <TableSectionV2 /> : <TableSection />}
      <ManageGroupListInfoSection />
    </MainContentStyled>
  )
}

const MainContentStyled = styled('div')`
  padding: 16px 8px 8px 24px;
`
