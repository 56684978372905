import React from 'react'
// @ts-ignore
import { primary, Tooltip } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import styled, { css } from 'react-emotion'
import {
  FirstRowStyled,
  SecondRowStyled,
  TableColumnHeaderStyled,
} from '../TableFirstColumnHeader/TableFirstColumnHeader'

interface TableColumnHeaderProps {
  hasMaxData?: boolean
  isGroupAdmin: boolean
  isPackageSupportsManage: boolean
  isUnlimited?: boolean
  licenseCount: string | number
  licenseTypeMessageKey: string
  licenseTypeMessageFallback: string
  licenseTypeMessageKeyUnlimited?: string
  licenseTypeMessageFallbackUnlimited?: string
  licenseTypeDescriptionMessageKey: string
  licenseTypeDescriptionMessageFallback: string
  maxCount?: string | number
  usedCount?: string | number
}

export function TableColumnHeader(props: TableColumnHeaderProps) {
  const {
    hasMaxData = false,
    isGroupAdmin,
    isPackageSupportsManage,
    isUnlimited = false,
    licenseCount,
    licenseTypeMessageKey,
    licenseTypeMessageFallback,
    licenseTypeMessageKeyUnlimited = '',
    licenseTypeMessageFallbackUnlimited = '',
    licenseTypeDescriptionMessageKey,
    licenseTypeDescriptionMessageFallback,
    maxCount = '',
    usedCount,
  } = props

  const tooltipContent = (
    <Localization
      messageKey="licensemanagement.maximum.numbers.of.licenses.available"
      fallback="Maximum number of licenses available"
    />
  )

  return (
    <TableColumnHeaderStyled>
      <FirstRowStyled>
        <FirstRowTextContainerStyled>
          <FirstRowTitleStyled data-testid="table-header-first-row">
            {typeof licenseCount !== 'undefined' && !isUnlimited
              ? `${licenseCount.toLocaleString()} `
              : ''}
            {(!isUnlimited || isGroupAdmin) && (
              <Localization
                messageKey={licenseTypeMessageKey}
                fallback={licenseTypeMessageFallback}
              />
            )}
            {isUnlimited && !isGroupAdmin && (
              <Localization
                messageKey={licenseTypeMessageKeyUnlimited}
                fallback={licenseTypeMessageFallbackUnlimited}
              />
            )}
          </FirstRowTitleStyled>
          <div data-testid="table-header-second-row">
            <Localization
              messageKey={licenseTypeDescriptionMessageKey}
              fallback={licenseTypeDescriptionMessageFallback}
            />
          </div>
        </FirstRowTextContainerStyled>
      </FirstRowStyled>
      <SecondRowStyled>
        <SecondRowContainerStyled>
          <SecondRowItemStyled>
            <SecondRowTitleStyled>
              {!isGroupAdmin && (
                <div data-testid="licenses-used-count">{usedCount}</div>
              )}
            </SecondRowTitleStyled>
            <SecondRowContentStyled>
              <Localization
                messageKey="licensemanagement.used.uppercase"
                fallback="USED"
              />
            </SecondRowContentStyled>
          </SecondRowItemStyled>
          <SecondRowItemStyled>
            {hasMaxData && isPackageSupportsManage && (
              <>
                <SecondRowTitleStyled>{maxCount}</SecondRowTitleStyled>
                <SecondRowContentStyled>
                  <span>
                    <Localization
                      messageKey="licensemanagement.max.uppercase"
                      fallback="MAX"
                    />
                  </span>
                  <Tooltip content={tooltipContent}>
                    <span className={tooltipIcon} />
                  </Tooltip>
                </SecondRowContentStyled>
              </>
            )}
          </SecondRowItemStyled>
        </SecondRowContainerStyled>
      </SecondRowStyled>
    </TableColumnHeaderStyled>
  )
}

const FirstRowTextContainerStyled = styled('div')`
  color: ${primary.gray(700)};
  padding-top: 16px;
`

const FirstRowTitleStyled = styled('div')`
  font-weight: bold;
  font-size: 20px;
`

const SecondRowContainerStyled = styled('div')`
  display: flex;
  padding: 16px 20px;
  color: ${primary.gray(700)};
`

const SecondRowItemStyled = styled('div')`
  width: 100%;
`

export const SecondRowTitleStyled = styled('div')`
  height: 20px;
  font-weight: bold;
  font-size: 16px;
`

export const SecondRowContentStyled = styled('div')`
  display: flex;
  font-size: 12px;
  color: ${primary.gray(500)};
`

const tooltipIcon = css`
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-top: 1px;
  margin-left: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24' width='24' %3E%3Cg fill='%23454B57'%3E%3Cpath d='M13.91,1.17A10.54,10.54,0,0,0,12,1a11,11,0,0,0-1.9,21.83A10.54,10.54,0,0,0,12,23a11,11,0,0,0,1.9-21.83Zm7.94,12.57A10,10,0,0,1,12,22a10.34,10.34,0,0,1-1.75-.15A10,10,0,0,1,12,2a10.34,10.34,0,0,1,1.75.15,10,10,0,0,1,8.11,11.59Z' /%3E%3Cpath d='M13.5,16H13V11.5a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0,0,1H12v4H10.5a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z' /%3E%3Ccircle cx='12' cy='8' r='1' /%3E%3C/g%3E%3C/svg%3E%0A");
`
