import React, { useEffect } from 'react'
import { LocalizationProvider } from '@workfront/localize-react'
import { Provider, useSelector } from 'react-redux'
import { Header } from './components/Header/Header'
import { MainContent } from './components/MainContent/MainContent'
import { ManageGroupListDialog } from './components/ManageGroupListDialog/ManageGroupListDialog'
import { store } from './store'
import { loadCustomer, selectCustomer } from './store/customer/customerSlice'
import { loadGroups } from './store/groups/groupsSlice'
import { useAppDispatch } from './store/hooks'
import { loadInfo } from './store/summary/summarySlice'
import { clientFactory } from './utils/L18n'

function AppDisconnected() {
  const dispatch = useAppDispatch()
  const { licenseTypeVersion } = useSelector(selectCustomer)

  useEffect(() => {
    dispatch(loadCustomer())
  }, [dispatch])

  useEffect(() => {
    if (licenseTypeVersion) {
      dispatch(loadInfo())
      dispatch(loadGroups())
    }
  }, [dispatch, licenseTypeVersion])

  return (
    <>
      <Header />
      <ManageGroupListDialog />
      <MainContent />
    </>
  )
}

export function App() {
  return (
    <LocalizationProvider clientFactory={clientFactory}>
      <Provider store={store}>
        <AppDisconnected />
      </Provider>
    </LocalizationProvider>
  )
}

// TODO react-emotion types
