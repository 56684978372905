import { BrowserClient } from '@wf-mfe/localize-react'

export const localizationClient = new BrowserClient({
  namespace: 'redrock',
  enableTerminology: false,
})

export const clientFactory = () => localizationClient

const LOCALE_CODE = localizationClient.getLocale()

const STANDARD_DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const standardDateFormatter = new Intl.DateTimeFormat(
  LOCALE_CODE,
  STANDARD_DATE_TIME_FORMAT_OPTIONS
)
