import { configureStore } from '@reduxjs/toolkit'
import activeRowReducer from './activeRow/activeRowSlice'
import customerSlice from './customer/customerSlice'
import groupsReducer from './groups/groupsSlice'
import manageGroupsDialogSlice from './manageGroupsDialog/manageGroupsDialogSlice'
import summaryReducer from './summary/summarySlice'

export const reducers = {
  summary: summaryReducer,
  groups: groupsReducer,
  activeRow: activeRowReducer,
  customer: customerSlice,
  manageGroupsDialog: manageGroupsDialogSlice,
}

export const store = configureStore({
  reducer: reducers,
  devTools: { name: 'Licenses' },
})

export { initialState } from './initialState'
export type AppDispatch = typeof store.dispatch
