import React from 'react'
import { TableContent } from './TableContent/TableContent'
import { TableHeader } from './TableHeader/TableHeader'

export function TableSection() {
  return (
    <>
      <TableHeader />
      <TableContent />
    </>
  )
}
