import React, { useCallback } from 'react'
import { Button, Dialog, Font } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import { selectGroups } from '../../store/groups/groupsSlice'
import { useAppDispatch } from '../../store/hooks'
import {
  saveChanges,
  selectManageGroupsDialogData,
  setDialogState,
  updateSaveButtonState,
} from '../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { DialogContent } from './DialogContent/DialogContent'

export function ManageGroupListDialog() {
  const dispatch = useAppDispatch()
  const groups = useSelector(selectGroups)
  const { dialogState, dialogGroupItems, saveIsActive } = useSelector(
    selectManageGroupsDialogData
  )

  const handleDialogClose = useCallback(() => {
    dispatch(setDialogState(false))
    dispatch(updateSaveButtonState(false))
  }, [dispatch])

  const handleSave = useCallback(() => {
    const groupIDs = groups.map((group) => group.ID)
    const dialogGroupIDs = dialogGroupItems.map((group) => group.ID)
    const addedGroupIDs = dialogGroupItems
      .filter((dialogGroup) => !groupIDs.includes(dialogGroup.ID))
      .map((group) => group.ID)
    const removedGroupIDs = groups
      .filter((group) => !dialogGroupIDs.includes(group.ID))
      .map((group) => group.ID)

    // Send data to backend only if there are added/removed groups
    if (addedGroupIDs.length > 0 || removedGroupIDs.length > 0) {
      dispatch(
        saveChanges({
          addedGroupIDs,
          removedGroupIDs,
        })
      )
      dispatch(updateSaveButtonState(false))
    } else {
      handleDialogClose()
    }

    // Set "Save" button to inactive state
    dispatch(updateSaveButtonState(false))
  }, [dialogGroupItems, dispatch, groups, handleDialogClose])

  const dialogHeader = (
    <Font type="h1">
      <Localization
        messageKey="licensemanagement.managegrouplist"
        fallback="Manage Group List"
      />
    </Font>
  )

  return dialogState ? (
    <Dialog
      onClose={handleDialogClose}
      width={665}
      initialFocus="#group-search-input"
      header={dialogHeader}
    >
      <DialogContent groups={groups} />
      <DialogFooter>
        <Button
          primary
          type="submit"
          onClick={handleSave}
          data-testid="manage-group-list-save-button"
          disabled={!saveIsActive}
        >
          <Localization messageKey="action.save" fallback="Save" />
        </Button>
        <Button text action={handleDialogClose}>
          <Localization messageKey="action.cancel" fallback="Cancel" />
        </Button>
      </DialogFooter>
    </Dialog>
  ) : null
}

const DialogFooter = styled('div')`
  padding-top: 8px;
`
