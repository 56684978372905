import React, { useCallback } from 'react'
import { Button, primary } from '@phoenix/all'
// @ts-ignore
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store/hooks'
import { setDialogState } from '../../../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { selectManageAccess } from '../../../../../store/summary/summarySlice'

export function TableFirstColumnHeader() {
  const dispatch = useAppDispatch()
  const { isPackageSupportsManage, isGroupAdmin } =
    useSelector(selectManageAccess)

  const handleClick = useCallback(() => {
    dispatch(setDialogState(true))
  }, [dispatch])

  return (
    <TableColumnHeaderStyled>
      <FirstRowStyled>
        {isPackageSupportsManage && !isGroupAdmin && (
          <Button onClick={handleClick} data-testid="manage-group-list-button">
            <Localization
              messageKey="licensemanagement.managegrouplist"
              fallback="Manage Group List"
            />
          </Button>
        )}
      </FirstRowStyled>
      <SecondRowStyled>
        <SecondRowTextStyled>
          <Localization
            messageKey="licensemanagement.homegroup.uppercase"
            fallback="HOME GROUP"
          />
        </SecondRowTextStyled>
      </SecondRowStyled>
    </TableColumnHeaderStyled>
  )
}

export const TableColumnHeaderStyled = styled('div')`
  background-color: ${primary.gray(50)};
`

export const FirstRowStyled = styled('div')`
  height: 72px;
  border-top: 1px solid ${primary.gray(200)};
  border-bottom: 1px solid ${primary.gray(200)};
  display: flex;
  align-items: center;
  padding-left: 20px;
`

export const SecondRowStyled = styled('div')`
  height: 59px;
  border-bottom: 1px solid ${primary.gray(200)};
`

export const SecondRowTextStyled = styled('div')`
  color: ${primary.gray(500)};
  font-size: 12px;
  padding-top: 32px;
  padding-left: 24px;
`
