import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { setDialogGroupItems } from '../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { Group } from '../../../types/Group'
import { GroupsContainer } from './GroupsContainer/GroupsContainer'
import { GroupTypeahead } from './GroupTypeahead/GroupTypeahead'

interface DialogContentProps {
  groups: Array<Group>
}

export function DialogContent({ groups }: DialogContentProps) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // We do this here as the list in the dialog can be changed at any time,
    // but the list in the table should be changed only after saving the changes
    dispatch(setDialogGroupItems([...groups]))
  }, [dispatch, groups])

  return (
    <div data-testid="manage-group-list-dialog-content">
      <GroupTypeahead />
      <GroupsContainer />
    </div>
  )
}
