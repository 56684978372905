import React, { useCallback, useMemo } from 'react'
import { Form, InputField, primary, secondary } from '@phoenix/all'
import { useLocalization } from '@workfront/localize-react'
// @ts-ignore
import { css, cx } from 'react-emotion'
import { useSelector } from 'react-redux'
import {
  NULL_VALUE_FOR_MAX,
  NUMERIC_REGEX,
} from '../../../../../../constants/constants'
import {
  activateRow,
  deactivateRow,
  selectActiveRowErrors,
  setMaxError,
  unsetMaxError,
} from '../../../../../../store/activeRow/activeRowSlice'
import { setLicenseTypeLimit } from '../../../../../../store/groups/groupsSlice'
import { useAppDispatch } from '../../../../../../store/hooks'
import { selectManageAccess } from '../../../../../../store/summary/summarySlice'
import { UsedLicenses } from '../../../../../../types/License'
import ErrorTooltip from '../../../../../Shared/ErrorTooltip'

interface TableBodyDataRowMaxColumnProps {
  licenseType: string
  licenseTypeName: keyof UsedLicenses
  groupID: string
  maxValue: number | null
  isRowHighlighted: boolean
  isRowActive: boolean
  usedLicenses: number
}

// TODO use MEMO
export function TableBodyDataRowMaxColumn(
  props: TableBodyDataRowMaxColumnProps
) {
  const {
    groupID,
    licenseType,
    licenseTypeName,
    maxValue,
    isRowHighlighted,
    isRowActive,
    usedLicenses,
  } = props
  const dispatch = useAppDispatch()
  const { isGroupAdmin } = useSelector(selectManageAccess)
  const errors = useSelector(selectActiveRowErrors)

  const customizedGroupID = `${groupID}-${licenseType}`
  const errorsForRow = errors[customizedGroupID]
  const doForceFocus =
    typeof errorsForRow !== 'undefined' ? errorsForRow.forceFocus : false
  const showInputField =
    !isGroupAdmin &&
    (isRowHighlighted ||
      isRowActive ||
      typeof errors[customizedGroupID] !== 'undefined')

  const MaxValueColor = isGroupAdmin ? MaxValueGray : MaxValueBlue
  const inputIncorrect =
    typeof errors[customizedGroupID] !== 'undefined' &&
    errors[customizedGroupID].licenseType === licenseType
  const newInputClass = inputIncorrect ? cx(InputWithError, NewInput) : NewInput

  const [noDataMessage] = useLocalization(
    'licensemanagement.not.applicable',
    'N/A'
  )

  const [invalidValueErrorMessage] = useLocalization(
    'licensemanagement.validation.only.numbers',
    'The Max value must be a nonnegative integer number.'
  )

  const [invalidMaxValueErrorMessage] = useLocalization(
    'exception.group.licensetypelimit.lessthanexist',
    'The Max value cannot be less than the number of Used licenses.'
  )

  const activateInput = useCallback(() => {
    if (!isGroupAdmin) {
      dispatch(activateRow(groupID))
    }
  }, [dispatch, groupID, isGroupAdmin])

  const validateInput = useCallback((inputText: string) => {
    return inputText === '' || NUMERIC_REGEX.test(inputText)
  }, [])

  const onInputBlur = useCallback(
    (inputComponent: React.FormEvent<HTMLInputElement>) => {
      const { currentTarget } = inputComponent

      let trimmedValue = currentTarget.value ? currentTarget.value.trim() : ''
      const numberValue = parseInt(trimmedValue, 10)
      // Remove leading zeros
      if (trimmedValue !== '' && Number.isInteger(Number(trimmedValue))) {
        trimmedValue = `${numberValue}`
      }
      if (currentTarget) {
        currentTarget.value = trimmedValue
      }

      let forceFocus

      if (typeof errorsForRow !== 'undefined') {
        forceFocus = errorsForRow.forceFocus
      } else {
        // Very first time to focus
        forceFocus = true
      }

      const isValidInput = validateInput(trimmedValue)

      if (
        isValidInput &&
        (Number.isNaN(numberValue) || numberValue >= +usedLicenses)
      ) {
        let valueHasChanged = false
        if (maxValue === null) {
          if (trimmedValue !== '') {
            valueHasChanged = true
          }
        } else if (`${maxValue}` !== trimmedValue) {
          valueHasChanged = true
        }

        if (valueHasChanged) {
          const val = trimmedValue === '' ? NULL_VALUE_FOR_MAX : trimmedValue

          dispatch(
            setLicenseTypeLimit({
              value: val,
              type: licenseType,
              ID: groupID,
              licenseTypeName,
              forceFocus,
              target: currentTarget,
            })
          )
          dispatch(deactivateRow())
        } else {
          // Remove error info for this field
          dispatch(
            unsetMaxError({
              groupID,
              licenseType,
            })
          )
          dispatch(deactivateRow())
        }
      } else {
        if (forceFocus) {
          currentTarget.focus()
        } else {
          dispatch(deactivateRow())
        }
        dispatch(
          setMaxError({
            errorGroupID: `${groupID}-${licenseType}`,
            errorLicenseType: licenseType,
            errorMessage: isValidInput
              ? invalidMaxValueErrorMessage
              : invalidValueErrorMessage,
            forceFocus,
          })
        )
      }
    },
    [
      dispatch,
      errorsForRow,
      groupID,
      invalidMaxValueErrorMessage,
      invalidValueErrorMessage,
      licenseType,
      licenseTypeName,
      maxValue,
      usedLicenses,
      validateInput,
    ]
  )

  const onKeyPress = useCallback((e: React.KeyboardEvent) => {
    // Save data when pressing "Enter" key
    if (e.which === 13 || e.keyCode === 13) {
      // @ts-ignore
      e.target.blur()
    }
  }, [])

  const maxValueElement = useMemo(() => {
    return maxValue !== null ? (
      <span className={MaxValueColor} data-testid="max-value-text">
        {maxValue}
      </span>
    ) : (
      <span className={NAMaxValue} data-testid="max-value-na">
        {noDataMessage}
      </span>
    )
  }, [MaxValueColor, maxValue, noDataMessage])

  return (
    <div
      onFocus={activateInput}
      className={fullWidthCss}
      data-testid="max-licenses-count"
    >
      <Form onSubmit={() => {}}>
        <span>
          {showInputField ? (
            <div
              className={newInputForm}
              data-testid="max-licenses-count-input-container"
            >
              <ErrorTooltip
                licenseType={licenseType}
                forceFocus={doForceFocus}
                error={errors[customizedGroupID]}
              />
              <InputField
                name="licenseNumber"
                validate={validateInput}
                className={newInputClass}
                placeholder={noDataMessage}
                defaultValue={maxValue !== null ? maxValue : undefined}
                onKeyDown={onKeyPress}
                onBlur={onInputBlur}
                maxLength={9}
              />
            </div>
          ) : (
            maxValueElement
          )}
        </span>
      </Form>
    </div>
  )
}

const NAMaxValue = css`
  opacity: 0.5;
`
const MaxValueBlue = css`
  color: ${primary.blue()};
`
const MaxValueGray = css`
  color: ${primary.gray(700)};
`

const InputWithError = css`
  border: 1px solid ${secondary.red(400)};
`

const NewInput = css`
  height: 34px;
  margin-top: 3px;
  margin-bottom: 2px;
  margin-left: -13px;
  width: 60%;
  input {
    cursor: pointer;
    font-family: 'adobe-clean', sans-serif;
    font-size: 16px;
  }
  .focused > input {
    cursor: pointer;
    font-family: 'adobe-clean', sans-serif;
    font-size: 16px;
  }
`

const newInputForm = css`
  #licenseNumber-error {
    display: none;
  }

  #licenseNumber-maxLength {
    display: none;
  }
`

export const fullWidthCss = css`
  width: 50%;
`
