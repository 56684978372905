// @ts-ignore
import { createReactLifecycles } from 'single-spa-workfront'
import { App } from './App'

const lifecycles = createReactLifecycles({
  rootComponent: App,
})

export const { bootstrap } = lifecycles
export const { mount } = lifecycles
export const { unmount } = lifecycles
export const Licenses = App
