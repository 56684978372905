import React from 'react'
import { primary } from '@phoenix/all'
// @ts-ignore
import styled from 'react-emotion'
import { Group } from '../../../../../types/Group'
import { GroupItem } from './GroupItem/GroupItem'

interface GroupsListProps {
  dialogGroupItems: Array<Group>
}

export function GroupsList({ dialogGroupItems }: GroupsListProps) {
  const groupsList = dialogGroupItems.map((group) => {
    return <GroupItem key={group.ID} group={group} />
  })

  return (
    <div>
      <GroupListStyled>{groupsList}</GroupListStyled>
    </div>
  )
}

const GroupListStyled = styled('ul')`
  border-bottom: 1px solid ${primary.gray(50)};
  max-height: 406px;
  overflow: auto;
`
