import React from 'react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import {
  selectLicenses,
  selectManageAccess,
} from '../../../../store/summary/summarySlice'
import { TableColumnHeader } from './TableColumnHeader/TableColumnHeader'
import { TableFirstColumnHeader } from './TableFirstColumnHeader/TableFirstColumnHeader'

export function TableHeader() {
  const {
    planLicenses,
    planLicensesUsed,
    requestLicenses,
    requestLicensesUsed,
    requestLicensesUnlimited,
    reviewLicenses,
    reviewLicensesUsed,
    reviewLicensesUnlimited,
    workLicenses,
    workLicensesUsed,
  } = useSelector(selectLicenses)

  const { isGroupAdmin, isPackageSupportsManage } =
    useSelector(selectManageAccess)

  const licensesPurchasedMessageKey = isGroupAdmin
    ? 'licensemanagement.licenses'
    : 'licensemanagement.licensespurchased'

  const licenseTypeDescriptionMessageFallback = isGroupAdmin
    ? 'Licenses'
    : 'Licenses purchased'

  return (
    <Layout data-testid="licenses-table-header">
      <Row>
        <Cell>
          <TableFirstColumnHeader />
        </Cell>
        <Cell>
          <TableColumnHeader
            hasMaxData
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            licenseCount={isGroupAdmin ? '' : planLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Plan"
            licenseTypeMessageKey="licensemanagement.plan"
            usedCount={planLicensesUsed}
          />
        </Cell>
        <Cell>
          <TableColumnHeader
            hasMaxData
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            licenseCount={isGroupAdmin ? '' : workLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Work"
            licenseTypeMessageKey="licensemanagement.work"
            usedCount={workLicensesUsed}
          />
        </Cell>
        <Cell>
          <TableColumnHeader
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            isUnlimited={reviewLicensesUnlimited}
            licenseCount={isGroupAdmin ? '' : reviewLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Review"
            licenseTypeMessageKey="licensemanagement.review"
            licenseTypeMessageFallbackUnlimited="Unlimited Review"
            licenseTypeMessageKeyUnlimited="licensemanagement.unlimitedreview"
            usedCount={reviewLicensesUsed}
          />
        </Cell>
        <Cell>
          <TableColumnHeader
            isGroupAdmin={isGroupAdmin}
            isPackageSupportsManage={isPackageSupportsManage}
            isUnlimited={requestLicensesUnlimited}
            licenseCount={isGroupAdmin ? '' : requestLicenses}
            licenseTypeDescriptionMessageFallback={
              licenseTypeDescriptionMessageFallback
            }
            licenseTypeDescriptionMessageKey={licensesPurchasedMessageKey}
            licenseTypeMessageFallback="Request"
            licenseTypeMessageKey="licensemanagement.request"
            licenseTypeMessageFallbackUnlimited="Unlimited Request"
            licenseTypeMessageKeyUnlimited="licensemanagement.unlimitedrequest"
            usedCount={requestLicensesUsed}
          />
        </Cell>
      </Row>
    </Layout>
  )
}

export const Layout = styled('div')``

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const Cell = styled('div')`
  width: 50%;
`
