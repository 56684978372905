import React from 'react'
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import {
  selectPlanLicensesOther,
  selectRequestLicensesOther,
  selectReviewLicensesOther,
  selectWorkLicensesOther,
} from '../../../../../store/summary/summarySlice'
import { Cell, Row } from '../../TableHeader/TableHeader'
import { columnCss } from '../TableBodyDataRow/TableBodyDataRow'
import { fullWidthCss } from '../TableBodyDataRow/TableBodyDataRowMaxColumn/TableBodyDataRowMaxColumn'

function TableBodyFirstRow() {
  const planLicensesOther = useSelector(selectPlanLicensesOther)
  const workLicensesOther = useSelector(selectWorkLicensesOther)
  const reviewLicensesOther = useSelector(selectReviewLicensesOther)
  const requestLicensesOther = useSelector(selectRequestLicensesOther)
  return (
    <RowBoldStyled>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>
          <Localization
            messageKey="licensemanagement.othergroups"
            fallback="Other Groups"
          />
        </div>
      </Cell>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>{planLicensesOther}</div>
      </Cell>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>{workLicensesOther}</div>
      </Cell>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>{reviewLicensesOther}</div>
      </Cell>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>{requestLicensesOther}</div>
      </Cell>
    </RowBoldStyled>
  )
}

export default TableBodyFirstRow

const RowBoldStyled = styled(Row)`
  font-weight: bold;
`
