import { fetchClient } from './api'

export const LicenseService = {
  loadInfo() {
    return fetchClient(`/internal/setup/license/details`)
  },
  loadInfoV2() {
    return fetchClient(`/internal/setup/license/adobe/details`)
  },
}
