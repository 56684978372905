import React from 'react'
import { useSelector } from 'react-redux'
import { selectManageGroupsDialogData } from '../../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { GroupSearch } from './GroupSearch/GroupSearch'
import { GroupsList } from './GroupsList/GroupsList'
import { ValidationMessageSection } from './ValidationMessageSection/ValidationMessageSection'

export function GroupsContainer() {
  const {
    dialogGroupItems,
    filteredGroupItems,
    cancelSearchIsActive,
    searchInputValue,
  } = useSelector(selectManageGroupsDialogData)

  return (
    <div>
      <ValidationMessageSection dialogGroupItems={dialogGroupItems} />
      <GroupSearch
        dialogGroupItems={dialogGroupItems}
        filteredGroupItems={filteredGroupItems}
        cancelSearchIsActive={cancelSearchIsActive}
        searchInputValue={searchInputValue}
      />
      <GroupsList
        dialogGroupItems={
          cancelSearchIsActive ? filteredGroupItems : dialogGroupItems
        }
      />
    </div>
  )
}
