import { createSlice } from '@reduxjs/toolkit'
import { GroupService } from '../../api/groupService'
import { AddRemoveLicenseTypeLimits } from '../../types/License'
import { loadGroups } from '../groups/groupsSlice'
import { AppDispatch } from '../index'
import { initialState } from '../initialState'
import { StoreType } from '../store-types'

export const manageGroupsDialogSlice = createSlice({
  name: 'Summary',
  initialState: initialState.manageGroupsDialog,
  reducers: {
    setDialogGroupItems: (state, action) => {
      return {
        ...state,
        dialogGroupItems: action.payload,
      }
    },
    setDialogState: (state, action) => {
      return {
        ...state,
        dialogState: action.payload,
      }
    },
    updateSaveButtonState: (state, action) => {
      return {
        ...state,
        saveIsActive: action.payload,
      }
    },
    updateCancelSearchButtonState: (state, action) => {
      return {
        ...state,
        cancelSearchIsActive: action.payload,
      }
    },
    filterGroups: (state, action) => {
      return {
        ...state,
        filteredGroupItems: state.dialogGroupItems.filter(
          (group) => action.payload.indexOf(group.ID) !== -1
        ),
      }
    },
    addGroup: (state, action) => {
      return {
        ...state,
        dialogGroupItems: [action.payload, ...state.dialogGroupItems],
      }
    },
    removeGroup: (state, action) => {
      return {
        ...state,
        dialogGroupItems: state.dialogGroupItems.filter(
          (group) => group.ID !== action.payload.ID
        ),
      }
    },
    setSearchInputValue: (state, action) => {
      return {
        ...state,
        searchInputValue: action.payload,
      }
    },
  },
})

export const {
  setDialogGroupItems,
  setDialogState,
  updateSaveButtonState,
  updateCancelSearchButtonState,
  filterGroups,
  addGroup,
  removeGroup,
  setSearchInputValue,
} = manageGroupsDialogSlice.actions

export const selectManageGroupsDialogData = (state: StoreType) =>
  state.manageGroupsDialog
export const selectDialogState = (state: StoreType) =>
  state.manageGroupsDialog.dialogState

export const saveChanges =
  ({ addedGroupIDs, removedGroupIDs }: AddRemoveLicenseTypeLimits) =>
  async (dispatch: AppDispatch) => {
    const {
      data: { result },
    } = await GroupService.addRemoveLicenseTypeLimits(
      addedGroupIDs,
      removedGroupIDs
    )
    if (result === null) {
      dispatch(loadGroups())
      dispatch(setDialogState(false))
    }
  }

export default manageGroupsDialogSlice.reducer
