import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { CustomerService } from '../../api/customerService'
import { initialState } from '../initialState'
import { StoreType } from '../store-types'

export const customerSlice = createSlice({
  name: 'Customer',
  initialState: initialState.customer,
  reducers: {
    setCustomer: (state, action) => {
      return {
        ...state,
        isIMSEnabled: Boolean(action.payload?.isIMSEnabled),
        licenseTypeVersion: action.payload?.licenseTypeVersion,
      }
    },
  },
})

export const { setCustomer } = customerSlice.actions

export const selectCustomer = (state: StoreType) => state.customer
export const selectIsNewLicenseTypeVersion = (state: StoreType) =>
  state.customer.licenseTypeVersion === 2

export const loadCustomer = () => async (dispatch: Dispatch) => {
  const { data } = await CustomerService.loadCustomer()
  dispatch(setCustomer(data?.[0]?.customer))
}

export default customerSlice.reducer
