import React, { useCallback } from 'react'
import { buildPartialWordSearchRegEx, primary } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
// @ts-ignore
import styled from 'react-emotion'
import { useAppDispatch } from '../../../../../store/hooks'
import {
  filterGroups,
  setSearchInputValue,
  updateCancelSearchButtonState,
} from '../../../../../store/manageGroupsDialog/manageGroupsDialogSlice'
import { Group } from '../../../../../types/Group'

interface GroupSearchProps {
  cancelSearchIsActive: boolean
  dialogGroupItems: Array<Group>
  filteredGroupItems: Array<Group>
  searchInputValue: string
}

export function GroupSearch({
  dialogGroupItems,
  filteredGroupItems,
  cancelSearchIsActive,
  searchInputValue,
}: GroupSearchProps) {
  const dispatch = useAppDispatch()
  const [searchText] = useLocalization(
    'licensemanagement.search.home.groups',
    'Search Home Groups in the list'
  )

  const searchGroupItems = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const inputNewValue = e.currentTarget.value
      const cancelSearchNewState = e.currentTarget.value.trim() !== ''
      const partialWordSearchRegEx = buildPartialWordSearchRegEx(inputNewValue)
      const filteredResultsIDs = dialogGroupItems
        .filter((item) => partialWordSearchRegEx.test(item.name))
        .map((item) => item.ID)

      dispatch(setSearchInputValue(inputNewValue))
      dispatch(filterGroups(filteredResultsIDs))
      dispatch(updateCancelSearchButtonState(cancelSearchNewState))
    },
    [dialogGroupItems, dispatch]
  )

  const cancelSearch = useCallback(() => {
    dispatch(setSearchInputValue(''))
    dispatch(updateCancelSearchButtonState(false))
  }, [dispatch])

  return (
    <SearchContainerStyled>
      <InputContainerStyled style={{}}>
        <InputStyled
          autoComplete="off"
          data-testid="group-search-input"
          id="group-search-input"
          placeholder={searchText}
          onChange={searchGroupItems}
          value={searchInputValue}
        />
        {cancelSearchIsActive && (
          <ButtonStyled type="button" onClick={cancelSearch}>
            <Localization messageKey="search.cancel" fallback="Cancel Search" />
          </ButtonStyled>
        )}
      </InputContainerStyled>
      {filteredGroupItems.length === 0 && cancelSearchIsActive && (
        <NoResultsStyled>
          <Localization
            messageKey="searchresults.none.generic"
            fallback="No results were found."
          />
        </NoResultsStyled>
      )}
    </SearchContainerStyled>
  )
}

const SearchContainerStyled = styled('div')`
  margin-top: 16px;
`

export const InputContainerStyled = styled('div')`
  display: flex;
`

export const InputStyled = styled('input')`
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='15' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%232F609F' fill='none' fill-rule='evenodd' stroke-linejoin='round'%3E%3Cpath d='M9.784 5.392a4.392 4.392 0 1 1-8.784 0 4.392 4.392 0 0 1 8.784 0z'/%3E%3Cpath d='M9.235 8.412l5.13 5.13' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left center;
  width: 100%;
  padding: 15px 8px 15px 20px;
  vertical-align: middle;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  position: relative;
`

export const ButtonStyled = styled('button')`
  color: ${primary.blue(500)};
  font-size: 12px;
  width: 80px;
  margin: 0 5px;
`

export const NoResultsStyled = styled('div')`
  font-size: 14px;
  color: ${primary.gray(400)};
  padding-top: 8px;
`
