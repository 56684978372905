import React from 'react'
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import styled from 'react-emotion'
import { useSelector } from 'react-redux'
import {
  selectFreeLicensesOther,
  selectLightLicensesOther,
  selectStandardLicensesOther,
} from '../../../../../store/summary/summarySlice'
import { Cell, Row } from '../../TableHeader/TableHeader'
import { columnCss } from '../TableBodyDataRow/TableBodyDataRow'
import { fullWidthCss } from '../TableBodyDataRow/TableBodyDataRowMaxColumn/TableBodyDataRowMaxColumn'

function TableBodyFirstRow() {
  const standardLicensesOther = useSelector(selectStandardLicensesOther)
  const lightLicensesOther = useSelector(selectLightLicensesOther)
  const freeLicensesOther = useSelector(selectFreeLicensesOther)
  return (
    <RowBoldStyled>
      <Cell className={columnCss}>
        <div className={fullWidthCss}>
          <Localization
            messageKey="licensemanagement.othergroups"
            fallback="Other Groups"
          />
        </div>
      </Cell>
      <Cell className={columnCss}>
        <div data-testid="standard-licenses-other" className={fullWidthCss}>
          {standardLicensesOther}
        </div>
      </Cell>
      <Cell className={columnCss}>
        <div data-testid="light-licenses-other" className={fullWidthCss}>
          {lightLicensesOther}
        </div>
      </Cell>
      <Cell className={columnCss}>
        <div data-testid="free-licenses-other" className={fullWidthCss}>
          {freeLicensesOther}
        </div>
      </Cell>
    </RowBoldStyled>
  )
}

export default TableBodyFirstRow

const RowBoldStyled = styled(Row)`
  font-weight: bold;
`
