import React from 'react'
import { primary } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
// @ts-ignore
import { css } from 'react-emotion'
import { GROUPS_LIMIT } from '../../../../../constants/constants'
import { Group } from '../../../../../types/Group'

interface ValidationMessageSectionProps {
  dialogGroupItems: Array<Group>
}

export function ValidationMessageSection({
  dialogGroupItems,
}: ValidationMessageSectionProps) {
  return dialogGroupItems.length >= GROUPS_LIMIT ? (
    <div id="showValidationErrorSection" className={WarningContent}>
      <Localization
        messageKey="licensemanagement.validation.groups.number.cannot.exceed"
        args={[GROUPS_LIMIT]}
        fallback="The total number of Groups cannot exceed {0}."
      />
    </div>
  ) : null
}

const WarningContent = css`
  display: inline-block;
  color: ${primary.gray(700)};
  background-color: rgba(255, 193, 7, 1);
  border-radius: 3px;
  font-size: 14px;
  padding: 6px 8px;
  width: auto;
  margin-top: 8px;
  margin-bottom: 8px;
`
