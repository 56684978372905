// @ts-expect-error the package @types/wf-mfe__api does not exist
import { wfetch } from '@wf-mfe/api'
import { responseHandler } from './responseHandler'

const BASE_URL = ''

export const fetchClient = (url?: string, options?: RequestInit) =>
  wfetch(
    `${BASE_URL}${url}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...options,
    },
    responseHandler
  )
