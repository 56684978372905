import React from 'react'
import { primary, secondary } from '@phoenix/all'
// @ts-ignore
import styled, { css } from 'react-emotion'

function ErrorTooltip({ error, licenseType, forceFocus }: any) {
  const showErrorTooltip =
    error && error.licenseType === licenseType && forceFocus
  const tooltipClass =
    typeof error !== 'undefined' && error.message.length > 35
      ? ErrorTooltipCorrectVerticalPos
      : ''

  return showErrorTooltip ? (
    <ErrorTooltipStyled
      className={tooltipClass}
      data-testid="max-error-tooltip"
    >
      <ErrorMessageStyled>{error && error.message}</ErrorMessageStyled>
    </ErrorTooltipStyled>
  ) : null
}

export default ErrorTooltip

export const ErrorTooltipStyled = styled('div')`
  position: absolute;
  width: 221px;
  max-width: 300px;
  margin-left: -5px;
  color: ${primary.white()};
  font-size: 12px;
  z-index: 999;
  background: ${secondary.red(400)};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  margin-top: -43px;
`

const ErrorTooltipCorrectVerticalPos = css`
  margin-top: -52px;
`

export const ErrorMessageStyled = styled('span')`
  display: inline-block;
  padding: 8px;
  white-space: normal;
`
