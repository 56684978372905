import { GroupsResponse } from '../types/Group'
import { LicenseTypeLimitRequest } from '../types/License'
import { fetchClient } from './api'

const GROUP_URL = '/attask/api-internal/GROUP'

export const GroupService = {
  loadGroups(): Promise<GroupsResponse> {
    return fetchClient(
      `${GROUP_URL}/search?licenseTypeLimit:groupID_Mod=notnull&name_Sort=asc&fields=licenseTypeLimit:planLimit,licenseTypeLimit:workLimit,licenseTypeLimit:standardLimit,licenseTypeLimit:lightLimit,licenseTypeLimit:usedLicenses`
    )
  },
  setMax(data: LicenseTypeLimitRequest) {
    return fetchClient(
      `${GROUP_URL}?action=setLicenseTypeLimit&method=PUT&updates={"groupID":"${data.ID}","ltLimit":${data.value},"licenseType":"${data.type}"}`
    )
  },
  addRemoveLicenseTypeLimits(
    addGroupIDs: Array<string>,
    removeGroupIDs: Array<string>
  ) {
    return fetchClient(
      `${GROUP_URL}?action=addRemoveLicenseTypeLimits&method=PUT&updates={"addGroupIDs":${JSON.stringify(
        addGroupIDs
      )},"removeGroupIDs":${JSON.stringify(removeGroupIDs)}}`
    )
  },
}
